import drupalAuth from '@/lib/drupal/api/authAxiosInstance';
import drupal, { jsonApiHeaders } from '@/lib/drupal/api/axiosInstance';
import { logErrorEvent } from '@/lib/util/logErrorEvent';
import axios, { AxiosResponse } from 'axios';

export type AuthRoles = 
  'authenticated' |
  'administrator' |
  'shockwave_unlimited' |
  'admin_set_shockwave_unlimited'

export interface AuthData {
  userId: string
  roles: AuthRoles[]
  csrfToken: string
  logoutToken: string
}

export interface AuthStatus {
  login_status: boolean
  user: null | {
    uid: string
    user_name: string
    display_name: string
    roles: AuthRoles[],
    email: string
  }
  csrf_token: null | string
  logout_token: null | string
}

export interface AuthSessionStatus {
  loginStatus: boolean
  lastVerifiedAt: number
}

export default class AuthModel {
  static async register (
    username: string, 
    email: string, 
    password: string, 
    agreeToEmail: boolean,
    preferredLayout?: 'classic' | 'modern'
  ): Promise<false | string> {
    const payload = {
      name: [
        {
          value: username
        }
      ],
      mail: [
        {
          value: email
        }
      ],
      pass: [
        {
          value: password
        }
      ],
      field_opt_in: [
        {
          value: agreeToEmail ? '1' : '0'
        }
      ]
    };
    if (preferredLayout) {
      payload[ 'field_theme' ] = [
        {
          value: preferredLayout
        }
      ];
    }

    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/register',
        params: { 
          _format: 'json'
        },
        data: payload
      });
    } catch (error) {
      logErrorEvent('Register', false, error);
      throw error;
    }
    
    return false;
  }

  static async login (name: string, password: string): Promise<AuthData> {
    let response: AxiosResponse;

    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/login',
        params: { 
          _format: 'json'
        },
        data: { 
          name, 
          pass: password
        }
      });
    } catch (error) {
      logErrorEvent('Login - Drupal', false, error);
      throw error;
    }
    
    return {
      userId: response.data.current_user.uid,
      roles: response.data.roles,
      csrfToken: response.data.csrf_token,
      logoutToken: response.data.logout_token
    };
  }

  static async logout (logoutToken: string): Promise<void> {
    try {
      await drupalAuth.request({
        method: 'post',
        url: '/user/logout',
        params: { 
          _format: 'json',
          token: logoutToken
        }
      });
    } catch (error) {
      logErrorEvent('Logout', true, error);
    }

    localStorage.setItem('.storage.sessionStatus', JSON.stringify({
      loginStatus: true,
      lastVerifiedAt: 0
    }));
  }

  static async status (): Promise<AuthStatus> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'get',
        url: '/sw/api/user-session',
        params: {
          _format: 'json'
        },
      });
    } catch (error) {
      logErrorEvent('Verify authentication', true, error);
      throw error;
    }

    localStorage.setItem('.storage.sessionStatus', JSON.stringify({
      loginStatus: response.data.login_status,
      lastVerifiedAt: Date.now()
    }));

    return response.data;
  }

  static async requestResetPassword (email: string): Promise<void> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/lost-password',
        params: {
          _format: 'json'
        },
        data: {
          mail: email,
          forgot_pass_link: `${process.env.NEXT_PUBLIC_BASE_URL}/account/reset`
        },
      });
    } catch (error) {
      logErrorEvent('Request password reset', false, error);
      throw error;
    }

    return response.data;
  }

  static async submitResetPassword (token: string, username: string, password: string): Promise<{ error: any, message: string }> {
    let response: AxiosResponse;
    try {
      response = await drupal.request({
        method: 'post',
        url: '/user/lost-password-reset',
        params: {
          _format: 'json'
        },
        data: {
          name: username,
          token, 
          new_pass: password
        },
      });
    } catch (error) {
      logErrorEvent('Submit password reset', false, error);
      throw error;
    }

    return response.data;
  }

  static async resendVerificationEmail (): Promise<void> {
    let response: AxiosResponse;
    try {
      response = await drupalAuth.request({
        method: 'post',
        url: '/sw/api/user-resend-verification',
        params: {
          _format: 'json'
        },
        headers: {
          'Content-Type': 'application/json',
        },
        data: {},
      });
    } catch (error) {
      logErrorEvent('Resend verification email', false, error);
      throw error;
    }
  }
}
