import { action, createStoreContext, CreateStoreOptions } from '@twocatmoon/react-actions';
import { TaxonomyStub } from '../drupal/models/Taxonomies';
import onlineGenres from '@/lib/data/onlineGenres.json';
import downloadGenres from '@/lib/data/downloadGenres.json';
import { ExtendedGameData } from '@/lib/drupal/models/Games';
import { Plan } from '@/lib/payments/models/SubscriptionModel';



function sortGenres (genres: TaxonomyStub[]) {
  return genres.sort((a, b) => {
    const orderA = 9999 - (a.order ?? 9999);
    const orderB = 9999 - (b.order ?? 9999);

    const order = orderB - orderA;
    return a.name.localeCompare(b.name) + order;
  });
}



// #region Schema & State

export interface AppState {
  taxonomies: {
    onlineGenres: TaxonomyStub[] | null
    downloadGenres: TaxonomyStub[] | null
  },
  promos: {
    annualPlan: Plan | null
  },
  mobileGames: ExtendedGameData[],
}

const initialState: AppState = {
  taxonomies: {
    onlineGenres: sortGenres(onlineGenres),
    downloadGenres: sortGenres(downloadGenres),
  },
  promos: {
    annualPlan: null,
  },
  mobileGames: [],
};

// #endregion



// #region Actions

export const appActions = {
  setOnlineGenres: action<AppState, TaxonomyStub[]>((prevState, genres) => {
    return {
      ...prevState,
      taxonomies: {
        ...prevState.taxonomies,
        onlineGenres: sortGenres(genres),
      }
    };
  }),
  
  setDownloadGenres: action<AppState, TaxonomyStub[]>((prevState, genres) => {
    return {
      ...prevState,
      taxonomies: {
        ...prevState.taxonomies,
        downloadGenres: sortGenres(genres),
      }
    };
  }),

  setAnnualCoupon: action<AppState, Plan>((prevState, plan) => {
    return {
      ...prevState,
      promos: {
        ...prevState.promos,
        annualPlan: plan,
      },
    };
  }),

  setMobileGames: action<AppState, ExtendedGameData[]>((prevState, games) => {
    return {
      ...prevState,
      mobileGames: games,
    };
  }),
};

// #endregion



// #region Export

const options: CreateStoreOptions = {
  ssr: true,
};

export const { 
  Provider: AppStoreProvider, 
  useStore: useAppStore, 
} = createStoreContext<AppState>(initialState, appActions, options);

// #endregion
