import { AppProps } from 'react-commons';
import { useEffectOnce } from 'react-commons';
import { authActions, authActionSets, useAuthStore } from '@/lib/drupal/stores/auth';
import { themeActions, useThemeStore } from '@/lib/stores/theme';
import Cookies from 'js-cookie';
import AuthModel from '@/lib/drupal/models/Auth';
import posthog from 'posthog-js';
import { useRef, useEffect } from 'react';

export function useDrupalAuth (onVerify?: AppProps['onAuthVerify'], onError?: AppProps['onAuthError']) {
  const [ authState, authDispatch, authExecute ] = useAuthStore();
  const [ _themeState, themeDispatch ] = useThemeStore();

  // Track initial page view
  const hasSentInitialPageView = useRef(false);
  useEffect(() => {
    if (authState.ready && !hasSentInitialPageView.current) {
      posthog.capture('$pageview', {
        layout: Cookies.get('sw_result_layout'),
      });
      hasSentInitialPageView.current = true;
    }
  }, [ authState.ready ]);

  // Verify login state
  useEffectOnce(
    () => {
      (async () => {
        if (authState.ready && authState.user) return;
        
        const status = await AuthModel.status();

        if (status.login_status) {
          await authExecute(authActionSets.verify({ 
            onError,
          }));
          if (onVerify) onVerify();
        } else {
          const layoutCookie = Cookies.get('sw_result_layout') as any;
          const layout = layoutCookie || 'modern';
          themeDispatch(themeActions.setLayout(layout));
          authDispatch(authActions.reset());
          authDispatch(authActions.setReady(true));
        }
      })();
    }, 
    () => {
      if (!authState.auth && !authState.user) {
        return true;
      }
    },
    [ authState.auth ]
  );
}
