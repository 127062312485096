/* eslint-disable no-console */
import ReactUA from 'react-ga';
import ReactGA4 from 'react-ga4';

export const universalAnalytics = {
  initialize (gaID: string) {
    console.log('[Google-UA] Initialized');
    ReactUA.initialize(gaID);
  },
  
  logPageView (props: Record<string, string | number> = {}) {
    console.log(`[Google-UA] PATH: ${window.location.pathname}`);
    ReactUA.set({ 
      page: window.location.pathname,
      ...props,
    });
    ReactUA.pageview(window.location.pathname);
  },
};

export const googleAnalytics = {
  initialize (gaID: string) {
    console.log('[Google-GA4] Initialized');
    ReactGA4.initialize(gaID);
  },

  logPageView (props: Record<string, string | number> = {}) {
    console.log(`[Google-GA4]: PATH ${window.location.pathname}`);
    ReactGA4.set({ 
      page: window.location.pathname,
      ...props
    });
    ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
  },
};
