import { useGoogleAnalytics, UseGoogleAnalyticsOptions } from '@/lib/hooks/useGoogleAnalytics';
import { Page } from '@common/components';
import { IntrinsicProps } from 'react-commons';

export interface AppProps extends IntrinsicProps {
  theme?: string
  googleAnalyticsOptions?: UseGoogleAnalyticsOptions
  onAuthVerify?: () => void
  onAuthError?: () => void
  authHook?: (onVerify: AppProps['onAuthVerify'], onError: AppProps['onAuthError']) => void
}

export default function App (props: AppProps) {
  if (props.authHook) props.authHook(props.onAuthVerify, props.onAuthError);
  useGoogleAnalytics(props.googleAnalyticsOptions);

  return (
    <Page theme={props.theme || 'default'}>
      <div className='App'>
        {props.children}
      </div>
    </Page>
  );
}

App.Main = function AppMain (props) {
  return (
    <main className={[ 'App__Main', props.padding && 'App__Main--padding' ].join(' ')}>
      {props.children}
    </main>
  );
};

App.Header = function AppHeader (props) {
  return (
    <header className={'App__Header'}>
      {props.children}
    </header>
  );
};

App.Footer = function AppFooter (props) {
  return (
    <footer className={'App__Footer'}>
      {props.children}
    </footer>
  );
};

App.Provider = function AppProvider (props) {
  return (
    <>
      {
        props.authProvider
          ? <props.authProvider>{props.children}</props.authProvider>
          : props.children
      }
    </>
  );
};
