import { useAuthStore } from '@/lib/drupal/stores/auth';
import { googleAnalytics, universalAnalytics } from '@/lib/util/googleAnalytics';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';


export interface UseGoogleAnalyticsOptions {
  ga4key?: string,
  uakey?: string
}

export const useGoogleAnalytics = (options: UseGoogleAnalyticsOptions) => {
  const router = useRouter();
  const [ authState ] = useAuthStore();

  const logPageView = () => {
    const props = {
      layout: Cookies.get('sw_result_layout'),
      subscriber_status: (authState.ready && authState.user)
        ? (authState.user?.isPremiumUser ? 'premium' : 'free')
        : '',
    };

    if (options.ga4key) googleAnalytics.logPageView(props);
    if (options.uakey) universalAnalytics.logPageView(props);
  };

  useEffect(() => {
    if (options.ga4key) googleAnalytics.initialize(options.ga4key);
    if (options.uakey) universalAnalytics.initialize(options.uakey);
    
    // The `routeChangeComplete` event won't fire on the initial page load unless the query 
    // string is hydrated. Log a page view if this is the first render with no query string.
    if (!router.asPath.includes('?')) logPageView();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ options.ga4key, options.uakey ]);

  useEffect(() => {
    // Listen for page changes on navigation changes or when the query changes
    router.events.on('routeChangeComplete', logPageView);
    return () => {
      router.events.off('routeChangeComplete', logPageView);
    };    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ router.events ]);

  return <></>;
};
